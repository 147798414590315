* {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--font-serif);
}

body {
	font-family: var(--font-serif);
	font-size: 1rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
}

h1 {
	font-weight: 400;
}

h2 {
	font-weight: 400;
}

h3 {
	font-weight: 400;
}

h4 {
	font-weight: 400;
}

h5 {
	font-weight: 400;
}

blockquote p::before {
	content: "“";
}

blockquote p::after {
	content: "”";
}
