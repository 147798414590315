.primary-navigation {
	font-weight: 500;
	margin: 0 auto 2.25rem;
	padding: 0;
	width: 90%;
}

@media (max-width: 1023px) {
	.primary-navigation {
		display: flex;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 768px) {
	.primary-navigation {
		margin-bottom: 4rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.primary-navigation {
		margin: 0;
		position: fixed;
		top: 50%;
		left: 100px;
		transform: translateY(-50%);
		width: auto;
	}

	.primary-navigation:hover a {
		opacity: 0.25;
		transition: opacity 256ms ease-in-out 128ms;
	}
}

.primary-navigation__item {
}

@media (max-width: 1023px) {
	.primary-navigation__item {
		border-right: 1px solid #e6e6e6;
		box-sizing: border-box;
		display: inline-block;
		width: 50%;
	}

	.primary-navigation__item:last-child {
		border-right: none;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.primary-navigation__item {
		border-bottom: 1px solid var(--color-medium-grey);
	}

	.primary-navigation__item:last-child {
		border-bottom: none;
	}
}

.primary-navigation__item a {
	color: var(--color-transparent-grey);
	display: block;
	font-size: 0.75rem;
	letter-spacing: 1px;
	padding: 1rem 0;
	text-align: center;
	text-transform: uppercase;
	transition: 256ms ease-in-out;
	white-space: nowrap;
	will-change: opacity;
}

.primary-navigation__item a:hover {
	color: var(--color-black);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.primary-navigation__item a {
		color: var(--color-black);
		line-height: 4rem;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 1.25rem;
		transition: opacity 0ms linear;
		width: 1.75rem;
		height: 4.125rem;
	}

	.primary-navigation__item a span + span {
		opacity: 0;
		transition: 256ms ease-in-out;
	}

	.primary-navigation__item a:hover {
		color: var(--color-black);
		opacity: 1;
	}

	.primary-navigation a:hover span + span {
		opacity: 1;
	}
}
