.project-block {
	display: block;
	margin: 0 auto 4.5rem;
	text-align: center;
	text-decoration: none;
	width: 100%;
	max-width: 40.625rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.project-block {
		margin: 0 auto 9.375rem;
	}
}

.project-block__title {
	color: black;
	font-size: 1.4rem;
	font-weight: 400;
	margin: 1.25rem 0;
	text-decoration: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.project-block__title {
		font-size: 1.55rem;
		margin: 2.5rem 0;
	}
}

@media (min-width: 1380px) {
	.project-block__title {
		font-size: 1.75rem;
	}
}

.project-block__intro {
	color: #949494;
	font-size: 0.8rem;
	font-weight: 400;
	margin: 0 auto;
	width: 90%;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.project-block__intro {
		font-size: 0.9rem;
	}
}

@media (min-width: 1380px) {
	.project-block__intro {
		font-size: 1rem;
		width: 100%;
	}
}

.project-block__intro p {
	margin: 1rem;
}

.project-block__image {
	background-color: #ececec;
	background-position: center;
	background-size: cover;
	display: block;
	margin-bottom: 1.15rem;
	transition: opacity 400ms ease-in-out;
	width: 100%;
}

.project-block__image.is-loading {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJsb2FkZXItMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiICAgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMCAwIDQwIDQwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0MCA0MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+ICA8cGF0aCBvcGFjaXR5PSIwLjIiIGZpbGw9IiMwMDAiIGQ9Ik0yMC4yMDEsNS4xNjljLTguMjU0LDAtMTQuOTQ2LDYuNjkyLTE0Ljk0NiwxNC45NDZjMCw4LjI1NSw2LjY5MiwxNC45NDYsMTQuOTQ2LDE0Ljk0NiAgICBzMTQuOTQ2LTYuNjkxLDE0Ljk0Ni0xNC45NDZDMzUuMTQ2LDExLjg2MSwyOC40NTUsNS4xNjksMjAuMjAxLDUuMTY5eiBNMjAuMjAxLDMxLjc0OWMtNi40MjUsMC0xMS42MzQtNS4yMDgtMTEuNjM0LTExLjYzNCAgICBjMC02LjQyNSw1LjIwOS0xMS42MzQsMTEuNjM0LTExLjYzNGM2LjQyNSwwLDExLjYzMyw1LjIwOSwxMS42MzMsMTEuNjM0QzMxLjgzNCwyNi41NDEsMjYuNjI2LDMxLjc0OSwyMC4yMDEsMzEuNzQ5eiIvPiAgPHBhdGggZmlsbD0iIzAwMCIgZD0iTTI2LjAxMywxMC4wNDdsMS42NTQtMi44NjZjLTIuMTk4LTEuMjcyLTQuNzQzLTIuMDEyLTcuNDY2LTIuMDEyaDB2My4zMTJoMCAgICBDMjIuMzIsOC40ODEsMjQuMzAxLDkuMDU3LDI2LjAxMywxMC4wNDd6Ij4gICAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlVHlwZT0ieG1sIiAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICB0eXBlPSJyb3RhdGUiICAgICAgZnJvbT0iMCAyMCAyMCIgICAgICB0bz0iMzYwIDIwIDIwIiAgICAgIGR1cj0iMC41cyIgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIvPiAgICA8L3BhdGg+ICA8L3N2Zz4=");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 2rem 2rem;
	opacity: 0.5;
	transition: opacity 0ms ease-in-out;
}

.project-block__image::before {
	content: "";
	display: block;
	padding-top: 100%;
}
