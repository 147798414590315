.section {
	padding-top: var(--space-32--rem);
	padding-bottom: var(--space-32--rem);
	position: relative;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.section {
		padding-top: var(--space-48--rem);
		padding-bottom: var(--space-48--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.section {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

/* from-wide,desktop,min-width-1280px */
@media (min-width: 80rem) {
	.section {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.section + .section {
	padding-top: 0;
}
