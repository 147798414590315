.wrapper {
	margin: 0 auto;
	width: 90%;
	max-width: 1340px;
}

.wrapper::after {
	clear: both;
	content: "";
	display: table;
}
