:root {
	/* #region Colors */
	--color-foreground: rgb(0 0 0);
	--color-background: rgb(255 255 255);
	--color-black: rgb(0 0 0);
	--color-white: rgb(255 255 255);
	--color-blue: rgb(0 0 255);
	--color-grey: rgb(148 148 148);
	--color-light-grey: rgb(247 247 247);

	/* colors with transparency */
	--color-black-muted: rgb(34 34 34 / 0.85);
	--color-grey-muted: rgb(34 34 34 / 0.4);
	--color-transparent-grey: rgb(34 34 34 / 0.25);
	--color-medium-grey: rgb(230 230 230);
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "astoria-sans", "Helvetica Neue", sans-serif;
	--font-serif: "astoria", "georgia", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-48--px: 48px;
	--space-48--rem: 3rem;
	/* #endregion Spacers */

	/* #region breakpoints */
	--min-width-xsmall: 568px;
	--min-width-small: 768px;
	--min-width-medium: 1024px;
	--min-width-large: 1380px;
	/* #endregion breakpoints */
}
