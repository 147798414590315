.image-grid {
	background-color: var(--color-light-grey);
	display: block;
	overflow: hidden;
	padding: 4rem 0 0;
	text-align: center;
}

.image-grid__image-container {
	display: block;
	margin-top: 0;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	position: relative;
	width: 90%;
	max-width: 40.625rem;
}

@media (min-width: 768px) {
	.image-grid__image-container {
		margin-bottom: 3rem;
	}
}

/* from-wide,desktop,min-width-1380px */
@media (min-width: 1380px) {
	.image-grid__image-container {
		margin-bottom: 4rem;
	}
}

.image-grid__image-caption {
	color: var(--color-grey);
	font-size: 0.75rem;
	line-height: 1.15rem;
	margin: 2rem auto;
	text-align: center;
	width: 90%;
}

.image-grid__image-container:hover .image-grid__image-caption {
	opacity: 1;
}

@media only screen and (min-width: 768px) {
	.image-grid__image-caption {
		font-size: 0.825rem;
		line-height: 1.2rem;
	}
}

@media only screen and (min-width: 1380px) {
	.image-grid__image-caption {
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		font-size: 0.875rem;
		line-height: 1.5rem;
		opacity: 0;
		position: absolute;
		text-align: left;
		top: 50%;
		right: -25vw;
		transform: translateY(-50%);
		transition: opacity 256ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
		width: 12.5vw;
	}
}
