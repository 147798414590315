.go-back-link {
	color: var(--color-grey-muted);
	display: block;
	font-family: var(--font-sans);
	font-size: 0.75rem;
	letter-spacing: 0.075rem;
	padding: 0 0 4rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 256ms ease-in-out;
}

.go-back-link:hover {
	color: var(--color-black-muted);
}

.go-back-link .go-back-link__arrow {
	opacity: 0.4;
	position: relative;
	top: -1px;
	left: -2px;
	transition: opacity 256ms ease-in-out;
}

.go-back-link:hover .go-back-link__arrow {
	opacity: 0.85;
}

.go-back-link__arrow {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEzLjYgNy42SDMuOEw3IDQuNWwtLjUtLjZMMi40IDhsNC4xIDQuMS41LS41LTMuMi0zLjJoOS45di0uOHoiLz48L3N2Zz4=");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;
	width: 1rem;
	height: 1rem;
}
