.site-header {
}

.site-header__logo {
	display: block;
	margin-top: 0.5rem;
	margin-right: auto;
	margin-bottom: 0;
	margin-left: auto;
	width: 10rem;
	height: 5rem;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 768px) {
	.site-header__logo {
		margin-top: 2rem;
		margin-right: auto;
		margin-bottom: 0;
		margin-left: auto;
		height: 6rem;
	}
}

@media (min-width: 1024px) {
	.site-header__logo {
		margin: 4rem auto;
		width: 15.625rem;
		height: 9rem;
	}
}

.site-header__logo svg {
	display: block;
	width: 100%;
}

.site-header__logo #top,
.site-header__logo #bottom {
	opacity: 0;
	transform: translate3d(0, -10px, 0);
	transition:
		opacity 768ms ease-in-out,
		transform 512ms ease-in-out 256ms;
}

.site-header__logo #bottom {
	transform: translate3d(0, 10px, 0);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 1024px) {
	.site-header__logo:hover #top,
	.site-header__logo:hover #bottom {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition:
			opacity 768ms ease-in-out,
			transform 512ms ease-in-out;
	}
}
